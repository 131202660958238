import type { NextPage } from 'next';
import Head from 'next/head';
import UserLayout from '@/components/UserLayout';
import Image from 'next/image';
import { MdClose } from 'react-icons/md';
import type { ChangeEvent } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import HorizontalLogo from '@/components/logos/HorizontalLogo';
import ToyokumoLogo from '@/components/ToyokumoLogo';
import ToyokumoKintoneAppText from '@/components/ToyokumoKintoneAppText';
import { useIsJa, useTranslation } from '@/hooks/i18n';
import Heading1 from '@/components/typography/Heading1';
import Heading3 from '@/components/typography/Heading3';
import SearchInput from '@/components/form/SearchInput';
import ViewOrFormLinkList from '@/components/ViewOrFormList';
import { useAuthContext } from '@/hooks/auth';
import { useAllProvidedFormsAndViewsWithProvider, useVisibleProviders } from '@/hooks/form-view-provider';
import { sortBy } from 'rambda';
import { useAccount } from '@/hooks/profile';
import CautionExplain from '@/components/CautionExplain';
import { useIsHideUserWelcomeMessage } from '@/hooks/localStorage';

function WelcomMessage() {
  const isJa = useIsJa();
  const { t } = useTranslation();
  const [isHide, setIsHide] = useIsHideUserWelcomeMessage();
  const onClose = useCallback(() => {
    setIsHide(true);
  }, [setIsHide]);

  if (isHide) return null;

  return (
    <div className="border-b border-toyokumo-gray-200 pb-10 lg:pt-4">
      <div className="relative bg-toyokumo-gray-50 px-4 pb-4 lg:pb-0">
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          className="absolute right-4 top-4"
          onClick={() => {
            onClose();
          }}
        >
          <MdClose size={16} />
        </button>
        <div className="flex flex-col items-center lg:min-h-[238px] lg:flex-row lg:space-x-4">
          <div className="w-[279px] lg:basis-[37.5%]">
            {isJa ? (
              <Image
                src="/user/fig-ac-fb1-1.png"
                width={240}
                height={160}
                alt="toyokumo kintoneapp user view"
                priority
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            ) : (
              <Image
                src="/user/fig-ac-fb1en-1.png"
                width={240}
                height={160}
                alt="toyokumo kintoneapp user view"
                priority
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            )}
          </div>
          <div className="flex flex-col items-center gap-y-4 lg:basis-[62.5%] lg:items-start">
            <HorizontalLogo
              logo={<ToyokumoLogo width={40} height={40} />}
              text={<ToyokumoKintoneAppText width={258} height={28} />}
              space={4}
            />
            <div>
              <Heading1>{t('user.welcome.title')}</Heading1>
              <Heading3>{t('user.welcome.explain')}</Heading3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type SearchFieldProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

function SearchField({ value, onChange }: SearchFieldProps) {
  return (
    <div className="hidden border-b border-toyokumo-gray-200 p-4 lg:block">
      <SearchInput value={value} onChange={onChange} />
    </div>
  );
}

// eslint-disable-next-line react/function-component-definition
const Index: NextPage = () => {
  const { t } = useTranslation();
  const user = useAuthContext();
  const [searchWord, setSearchWord] = useState('');
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchWord(e.target.value);
  const [showNavigationDrawer, setShowNavigationDrawer] = useState(false);
  const { data: account } = useAccount();
  const { data: providers, isPending } = useVisibleProviders();

  const { data: formsAndViewsWithProviders, isPending: isFormViewPending } = useAllProvidedFormsAndViewsWithProvider();

  // 検索のたびにdescriptionを小文字化するのは負荷が大きい可能性があるので、メモ化しておく
  const lowerDescriptionByUrl = useMemo(
    () =>
      Object.fromEntries(
        formsAndViewsWithProviders?.flatMap((provider) =>
          [...(provider.forms ?? []), ...(provider.views ?? [])].map((viewOrForm) => [
            viewOrForm.url,
            viewOrForm.description?.toLocaleLowerCase() ?? '',
          ]),
        ) ?? [],
      ),
    [formsAndViewsWithProviders],
  );
  const viewOrFormLinks = useMemo(() => {
    if (!formsAndViewsWithProviders || isFormViewPending) return undefined;
    const lowerSearchWord = searchWord.toLocaleLowerCase();
    return sortBy(
      (x) => x.viewOrFormTitle,
      formsAndViewsWithProviders.flatMap((provider) =>
        [...(provider.forms ?? []), ...(provider.views ?? [])].map((viewOrForm) => ({
          avatarUrl: provider.avatarUrl ?? null,
          providerName: provider.name ?? '',
          uid: provider.providerId ?? '',
          viewOrFormTitle: viewOrForm.title,
          description: viewOrForm.description ?? '',
          href: viewOrForm.url,
        })),
      ),
    ).filter(
      ({ providerName, viewOrFormTitle, href }) =>
        searchWord.length === 0 ||
        providerName.toLocaleLowerCase().includes(lowerSearchWord) ||
        viewOrFormTitle.toLocaleLowerCase().includes(lowerSearchWord) ||
        lowerDescriptionByUrl[href].includes(lowerSearchWord),
    );
  }, [formsAndViewsWithProviders, isFormViewPending, lowerDescriptionByUrl, searchWord]);

  const providerList = useMemo(
    () =>
      providers?.map(({ providerId, name, avatarUrl }) => ({
        id: providerId,
        name: name ?? '',
        avatarUrl: avatarUrl ?? null,
        href: `/account/${providerId}`,
      })),
    [providers],
  );

  const isViewOrFormsEmpty = viewOrFormLinks && viewOrFormLinks.length === 0;
  const noMatchingPageForSearch = searchWord.length > 0 && isViewOrFormsEmpty;

  return (
    <>
      <Head>
        <title>ホーム - Toyokumo kintoneApp アカウント</title>
      </Head>
      <UserLayout
        providerList={providerList}
        profileAvatarUrl={account?.account?.avatarUrl ?? null}
        profileName={account?.account?.name ?? null}
        profileEmail={user?.email || ''}
        profileUid={user?.uid || ''}
        providerUid=""
        searchWord={searchWord}
        onChangeSearch={onChangeSearch}
        showNavigationDrawer={showNavigationDrawer}
        setShowNavigationDrawer={setShowNavigationDrawer}
      >
        <div className="flex-col pb-8 lg:px-4">
          <WelcomMessage />
          <SearchField value={searchWord} onChange={onChangeSearch} />
          {noMatchingPageForSearch ? (
            <div className="p-4">
              <CautionExplain title={t('user.noMatchingViewForSearch') as string} />
            </div>
          ) : (
            <ViewOrFormLinkList viewOrFormLinks={viewOrFormLinks} isPending={isPending} />
          )}
        </div>
      </UserLayout>
    </>
  );
};

export default Index;
